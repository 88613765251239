<template>
  <div>
    <!--
    <div v-if="!facilityLevel">
      <span v-if="editMode" class="ml-6">Details, rules, and fees involved with a tenant’s move-out process.</span>
      <span v-else class="ml-6"><span class="font-weight-medium">Step 3: </span>Provide the details, rules, and fees involved with a tenant’s move-out process.</span>
      <v-divider class="mt-3"></v-divider>
    </div>
    -->
    <hb-form
      label="Move-Out Settings"
      :required="!readMode"
      :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :active.sync="active"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      :auto-layout="false"
      full
      :last="!readMode"
      :divider="readMode ? false : true"
      :content-position="!readMode ? contentPosition : undefined"
    >
      <template v-slot:display>
        <hb-checkbox
          v-model="template.allow_back_days"
          readonly
        >
          <template v-slot:label>
            Allow back date for {{ template.allow_back_days }} {{ template.allow_back_days === 1 ? 'day' : 'days' }}.
          </template>
        </hb-checkbox>

        <hb-checkbox
          v-model="template.prorate_rent_out"
          readonly
          class="mt-3"
        >
          <template v-slot:label>
            Prorate move-out until {{ template.prorate_days }} {{ template.prorate_days === 1 ? 'day' : 'days' }} after the start of the service period.
          </template>
        </hb-checkbox>
      </template>
      <template v-slot:edit>
        <hb-checkbox
          v-model="template.backDaysCheck"
          id="back_days_check"
          name="back_days_check"
          align-top
          class="mt-1"
        >
          <template v-slot:content>
            <v-row no-gutters class="mt-n3">
              <div class="hb-inline-sentence-text">
                Allow back date for
              </div>
              <HbSelect
                condensed
                box
                small
                v-model="template.allow_back_days"
                :items="allowedBackDays"
                id="back_days"
                name="back_days"
                placeholder="0"
              />
              <div class="hb-inline-sentence-text">
                day(s). (Max 10 days)
              </div>
            </v-row>
          </template>
        </hb-checkbox>

        <hb-checkbox
          v-model="template.prorate_rent_out"
          id="prorate_move_out"
          name="prorate_move_out"
          align-top
          class="mt-3"
        >
          <template v-slot:content>
            <v-row no-gutters class="mt-n3">
              <div class="hb-inline-sentence-text">
                Prorate move-out until
              </div>
              <HbSelect
                condensed
                box
                small
                v-model="template.prorate_days"
                :items="allowedProrateDays"
                id="prorate_move_out_select"
                name="prorate_move_out)select"
                placeholder="0"
              />
              <div class="hb-inline-sentence-text">
                day(s) after the start of service period.
              </div>
            </v-row>
          </template>
        </hb-checkbox>
      </template>
    </hb-form>

    <!--
    <v-row class="mr-0 pl-3">
      <v-col class="field-label pa-0 pl-4" cols="3" :style="!facilityLevel ? 'min-height: 28em;' : ''">
        <div class="font-weight-medium pt-2">
          Move-Out Settings*
        </div>
      </v-col>
      <v-col
        class="font-weight-regular pa-0"
        style="color: #101318"
        cols="9"
      >
        <hoverable-edit-field
          :activeEditing="activelyEditing == 'moveOut'"
          :hasPermissionToEdit="isPermissionToEdit"
          name="moveOut"
        >
        <template slot="editingMode">
          <v-row class="ma-0 pa-0">
            <v-col cols="4" class="ma-0 pa-0 pt-2" style="max-width: 11.5em;">
              <v-checkbox
                class="ma-0 pa-0"
                flat
                hide-details
                id="consecutive_pay"
                name="consecutive_pay"
                single-line
                solo
                v-model="template.backDaysCheck"
              >
                <template v-slot:label>
                  <span class="hb-text-night">Allow back date for</span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 pt-1 px-2" style="max-width: 5em">
              <v-select
                :items="allowedBackDays"
                hide-details
                single-line
                id="unit_type"
                name="unit_type"
                data-vv-as="space type"
                class="pa-0 ma-0"
                v-model="template.allow_back_days"
              >
              </v-select>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0 pt-3">
              day(s). (Max 10 days)
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col cols="5" class="ma-0 pa-0 pt-2" style="max-width: 12.8em">
              <v-checkbox
                class="ma-0 pa-0"
                flat
                hide-details
                id="prorate_move_out"
                name="prorate_move_out"
                single-line
                solo
                v-model="template.prorate_rent_out"
              >
                <template v-slot:label>
                  <span class="hb-text-night">Prorate move-out until</span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 pt-1 px-2" style="max-width: 5em">
              <v-select
                :items="allowedProrateDays"
                hide-details
                single-line
                id="unit_type"
                name="unit_type"
                data-vv-as="space type"
                class="pa-0 ma-0"
                v-model="template.prorate_days"
              >
              </v-select>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0 pt-3">
              day(s) after the start of service period.
            </v-col>
          </v-row>
        </template>
        <template slot="readMode" v-if="editMode">
          <v-row class="pa-0 ma-0">
            <v-col :class="backDaysColor" class="pa-0 ma-0" cols="12">
              <v-icon color="#101318" class="pr-2" v-if="template.allow_back_days">mdi-check</v-icon>
              <v-icon color="#C4CDD5" class="pr-2" v-else>mdi-close</v-icon>
              Allow back date for {{ template.allow_back_days }} days.
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0 pt-2">
            <v-col :class="prorateRentOutColor" class="pa-0 ma-0" cols="12">
              <v-icon color="#101318" class="pr-2" v-if="template.prorate_rent_out">mdi-check</v-icon>
              <v-icon color="#C4CDD5" class="pr-2" v-else>mdi-close</v-icon>
              Prorate move-out until {{ template.prorate_days }} days after the start of the service period.
            </v-col>
          </v-row>
        </template>
        </hoverable-edit-field>
      </v-col>
    </v-row>
    -->

  </div>
</template>

<script type="text/babel">
// import HoverableEditField from "../../assets/HoverableEditField.vue";
import { mapGetters } from "vuex";
import { EventBus } from '../../../EventBus.js';

export default {
  // components: { HoverableEditField },
  name: "LeaseConfigMoveOut",
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    readMode: {
      type: Boolean,
      default: false,
    },
    activelyEditing: {
      type: String,
      default: "",
    },
    template: {
      type: Object,
      required: true
    },
    facilityLevel: {
      type: Boolean,
      default: false
    },
    contentPosition: {

    }
  },
	data() {
    return {
      active: ''
    };
  },
  created(){
    EventBus.$on('lease-configuration-edit-mode-clear-active', this.clearActive);
  },
  destroyed() {
    EventBus.$off('lease-configuration-edit-mode-clear-active', this.clearActive);
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
    allowedBackDays() {
      let days = Array(10).fill(0).map((n, i) => i + 1);
      return days;
    },
    allowedProrateDays() {
      let days = Array(30).fill(0).map((n, i) => i + 1);
      return days;
    },
    isPermissionToEdit() {
      return this.facilityLevel ? false : this.hasPermission('view_edit_templates');
    },
    backDaysColor(){
      return {
          'unselected-field': !this.template.allow_back_days
      }
    },
    prorateRentOutColor(){
      return {
          'unselected-field': !this.template.prorate_rent_out
      }
    },
  },
  methods: {
    handleEmittedCancel(){
      this.$emit('cancelEdit');
    },
    handleEmittedSave(){
      this.$emit('saveData');
    },
    clearActive(){
      this.active = '';
    }
  }
};
</script>

<style scoped>
.field-label {
  background-color: #f9fafb;
  color: #101318;
}
</style>
