<template>
  <div>
    <!--
    <div v-if="!facilityLevel">
      <span v-if="editMode" class="ml-6"
      >The name and description for this lease.</span 
      >
      <span v-else class="ml-6">
        <span class="font-weight-medium">Step 1:</span> Provide a name and
        description for this lease.</span
      >
      <v-divider class="mt-3"></v-divider>
    </div>
    -->
    <hb-form
      label="Name"
      :required="!readMode"
      :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :active.sync="active"
      :empty="!template.name && !readMode"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
    >
      <template v-slot:display>
        {{ template.name }}
      </template>
      <template v-slot:edit>
        <hb-text-field
          v-model="template.name"
          v-validate="'required|max:45'"
          id="template_name"
          name="template_name"
          data-vv-scope="form_1"
          data-vv-as="Name"
          :error="errors.has('form_1.template_name')"
          placeholder="Enter Name"
          autofocus
        ></hb-text-field>
      </template>
    </hb-form>

    <hb-form
      label="Description"
      :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :active.sync="active"
      :empty="!template.description"
      full
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
    >
      <template v-slot:display>
        {{ template.description }}
      </template>
      <template v-slot:edit>
        <hb-textarea
          v-model="template.description"
          v-validate="'max:1000'"
          name="description"
          id="description"
          data-vv-scope="form_1"
          data-vv-as="Description"
          :error="errors.has('form_1.description')"
          placeholder="Enter Description"
          :autofocus="editMode"
        ></hb-textarea>
      </template>
    </hb-form>

    <span v-if="showSpaceType !== false">

      <hb-form
        label="Space Type"
        :required="!readMode"
        :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
        :start-open="!editMode && !readMode"
        :hide-actions="!editMode && !readMode"
        :active.sync="active"
        :empty="!template.unit_type && !readMode"
        @cancel="handleEmittedCancel"
        @save="handleEmittedSave"
        last
        :content-position="contentPosition"
      >
        <template v-slot:display>
          {{ template.unit_type | capitalize }}

          <hb-checkbox
            v-model="template.is_default"
            label="Set as Default for All Properties"
            readonly
            class="pt-2"
          >

          </hb-checkbox>
        </template>
        <template v-slot:edit>
          <hb-select
            v-model="template.unit_type"
            :items="unitTypes"
            v-validate="'required'"
            id="unit_type"
            name="unit_type"
            data-vv-scope="form_1"
            data-vv-as="Space Type"
            :error="errors.has('form_1.unit_type')"
            placeholder="Select Space Type"
            :clearable="false"
            class="general-cofigurations-hb-select-fix-wrapper"
          >
            <template v-slot:item="{ item }">
              <span class="general-configurations-select-text-night-fix">{{ item | capitalize }}</span>
            </template>
          </hb-select>

          <hb-checkbox
            v-model="template.is_default"
            id="checkbox-is-default"
            name="checkbox-is-default"
            data-name="checkbox-is-default"
            label="Set as Default for All Properties"
          >
          </hb-checkbox>
        </template>
      </hb-form>

    </span>
    <!--
    <v-row class="mr-0 pl-3">
      <v-col class="field-label pl-4" cols="3">
        <div>
          <span class="font-weight-medium">Name*</span>
        </div>
      </v-col>
      <v-col class="pa-0" cols="9">

        <hoverable-edit-field
          :activeEditing="activelyEditing == 'lease_name'"
          :hasPermissionToEdit="isPermissionToEdit"
          name="lease_name"
        >
          <template slot="editingMode">
            <v-row class="pa-0 ma-0">
              <v-col class="pa-0 ma-0" cols="6">
                <v-text-field
                  single-line
                  :error-messages="errors.first('form_1.template_name')"
                  hide-details
                  v-model="template.name"
                  label="Enter name"
                  id="template_name"
                  name="template_name"
                  v-validate="'required|max:45'"
                  data-vv-scope="form_1"
                  data-vv-as="name"
                  class="pt-1 ma-0"
                  :class="{ 'custom-field-error': errors.first('form_1.template_name') }"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>

          <template slot="readMode" v-if="editMode">
            {{ template.name }}
          </template>
        </hoverable-edit-field>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row class="mr-0 pl-3">
      <v-col class="field-label pl-4" cols="3">
        <div>
          <span class="font-weight-medium">Description</span>
        </div>
      </v-col>
      <v-col class="pa-0" cols="9">
        <hoverable-edit-field
          :activeEditing="activelyEditing == 'lease_description'"
          :hasPermissionToEdit="isPermissionToEdit"
          name="lease_description"
        >
          
          <template slot="editingMode">
            <v-row class="pa-0 ma-0">
              <v-col class="pa-0 ma-0" cols="12">
                <v-textarea
                  rows="2"
                  hide-details
                  name="description"
                  id="description"
                  v-model="template.description"
                  v-validate="'max:1000'"
                  data-vv-scope="form_1"
                  placeholder="Enter a description"
                  class="pt-1 pb-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </template>
          
          <template slot="readMode" v-if="editMode">
            <span v-if="template.description">
              {{ template.description }}
            </span>
            <span v-else>
            </span>
          </template>
        </hoverable-edit-field>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row class="mr-0 pl-3" v-if="!facilityLevel">
      <v-col class="field-label pl-4" cols="3" style="min-height: 23em">
        <div>
          <span class="font-weight-medium">Space Type*</span>
        </div>
      </v-col>
      <v-col class="pa-0" cols="9">
        <hoverable-edit-field
          :activeEditing="activelyEditing == 'unit_type'"
          :hasPermissionToEdit="isPermissionToEdit"
          name="unit_type"
        >
          
          <template slot="editingMode">
            <v-row class="pa-0 ma-0">
              <v-col class="pa-0 ma-0" cols="8">
                <v-select
                  :error-messages="errors.first('form_1.unit_type')"
                  :items="unitTypes"
                  hide-details
                  single-line
                  label="Select Space Type"
                  v-validate="'required'"
                  data-vv-scope="form_1"
                  id="unit_type"
                  name="unit_type"
                  data-vv-as="space type"
                  v-model="template.unit_type"
                  class="pt-0 hb-text-night"
                  :class="{
                    'custom-field-error': errors.first('form_1.unit_type'),
                  }"
                >
                  <template v-slot:selection="{ item }">
                    {{ item | capitalize }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item | capitalize }}
                  </template>
                </v-select>
                
                <hb-checkbox
                  v-model="template.is_default"
                  id="checkbox-is-default"
                  name="checkbox-is-default"
                  data-name="checkbox-is-default"
                  class="mt-1"
                  :disabled="isCheckBoxChecked"
                >
                  <template v-slot:label>
                    <span class="hb-text-night">Set as Default for All Properties</span>
                  </template>
                  <template v-slot:tooltipBody v-if="(isCheckBoxChecked==true)">
                      Please mark your designated template as default to remove this template from default.
                  </template>

                </hb-checkbox>
              </v-col>
            </v-row>
          </template>
          
          <template slot="readMode" v-if="editMode">
            <v-row class="pa-0 ma-0">
              <v-col class="pa-0 ma-0 text-capitalize" cols="12">
                {{ template.unit_type }}
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0">
              <v-col class="pl-0 pt-2" cols="12" v-if="isPermissionToEdit">
                <v-icon class="pr-2" v-if="template.is_default" color="#101318"
                  >mdi-check</v-icon
                >
                Set as Default for All Properties
              </v-col>
            </v-row>
          </template>
        </hoverable-edit-field>
      </v-col>
    </v-row>
    -->
  </div>
</template>

<script type="text/babel">
// import HoverableEditField from "../../assets/HoverableEditField.vue";
import { mapGetters } from "vuex";
import { EventBus } from '../../../EventBus.js';

export default {
  name: "GeneralConfigurations",
  /*
  components: {
    HoverableEditField,
  },
  */
  props: ["template", "activelyEditing", "editMode", "readMode", "facilityLevel", "showSpaceType", "contentPosition"],
  data() {
    return {
      unitTypes: ["storage", "parking"],
      active: '',
      isCheckBoxChecked: false,
      isFormStatusSave: false,

    };
  },
  created(){
    EventBus.$on('lease-configuration-edit-mode-clear-active', this.clearActive);
  },
  destroyed() {
    EventBus.$off('lease-configuration-edit-mode-clear-active', this.clearActive);
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
    isPermissionToEdit() {
      return this.facilityLevel ? false : this.hasPermission('view_edit_templates');
    }
  },
  methods: {
    async validateForm() {
      let status = await this.$validator.validateAll("form_1");
      this.isFormStatusSave = (status?true:false)
      return status;
    },
    handleEmittedCancel(){
      this.$emit('cancelEdit');
    },
    handleEmittedSave(){
      this.$emit('saveData');
    },
    clearActive(){
      this.active = '';
    }
  },
  watch: {
    "template.unit_type"(value) {
      if(this.template && this.template.unit_type && value !== 'parking') {
        this.template.checklist["vehicle"] = [];
      }
    },
    // Modal checks checkbox's default value: if 1, checkbox disabled; else, enabled.
    "template.is_default"(value) {
      if(value === 1){
        this.isCheckBoxChecked = true;
      }
    },
    // Checkbox is enabled for editing if its not disabled already and gets disabled after it's saved. 
    "activelyEditing"(value){
      if(this.template.is_default && this.isFormStatusSave && value === ""){
        this.isCheckBoxChecked = true;
      }
    }
  }
};
</script>

<style scoped>
.field-label {
  background-color: #f9fafb;
  color: #101318;
}

.general-cofigurations-hb-select-fix-wrapper >>> .v-select__selection.v-select__selection--comma {
  text-transform: capitalize !important;
}

.general-configurations-select-text-night-fix {
  color: #101318 !important;
}
</style>
